
import Vue from "vue";
import AsideMain from "@/components/layouts/AsideMain.vue";
import Dialog from "@/components/layouts/Dialog.vue";
import Footer from "@/components/layouts/Footer.vue";
import Outline from "@/components/layouts/Outline.vue";
import Alert from "@/components/Alert.vue";
import Anchor from "@/components/Anchor.vue";
import MultiInput from "@/components/MultiInput.vue";
import Events from "@/views/admin/courses/Events.vue";
import Metrics from "@/views/admin/courses/Metrics.vue";

import api from "@/api";
import * as types from "@/types";
import store from "@/store";

export default Vue.extend({
  components: {
    AsideMain,
    Dialog,
    Footer,
    Outline,
    Alert,
    Anchor,
    MultiInput,
    Events,
    Metrics,
  },
  data() {
    return {
      course: new types.Course(),
      loading: true,
      form: {
        course: new types.Course(),
        error: "",
        dirty: false,
        invalid: true,
        rules: {
          displayName: [{ required: true }],
          externalID: [{ required: true }],
          dueAt: [{ required: true }],
        },
      },
      update: {
        loading: false,
        notification: {
          visible: false,
        },
      },
      updateStatus: {
        loading: false,
      },
      clone: {
        loading: false,
      },
      remove: {
        loading: false,
        visible: false,
        form: {
          name: "",
          error: "",
          rules: {
            name: [{ required: true }],
          },
        },
      },
      outline: [
        {
          text: "General",
          link: "#general",
        },
        {
          text: "Recent Security Activity",
          link: "#recent-security-activity",
        },
      ],
    };
  },
  computed: {
    readonly(): boolean {
      return !store.getters.hasTrainingAdminRole();
    },
    externalURL(): string {
      return `https://learn.acronis.com/learn/courses/${this.form.course.externalID}`;
    },
  },
  methods: {
    load() {
      this.loading = true;
      api
        .get(`/api/v1/courses/${this.$route.params.id}`)
        .then((response) => {
          this.course = new types.Course(response.data);
          this.form.course = new types.Course(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateCourse() {
      this.update.loading = true;
      api
        .put(`/api/v1/courses/${this.$route.params.id}`, this.form.course)
        .then((response) => {
          this.update.notification.visible = true;
          this.form.dirty = false;
          this.load();
        })
        .finally(() => {
          this.update.loading = false;
        });
    },
    updateCourseStatus(enabled: boolean) {
      this.updateStatus.loading = true;
      api
        .put(`/api/v1/courses/${this.$route.params.id}/status`, {
          enabled: enabled,
        })
        .then((response) => {
          this.update.notification.visible = true;
          this.course.enabled = enabled;
          this.form.course.enabled = enabled;
        })
        .finally(() => {
          this.updateStatus.loading = false;
        });
    },
    cloneCourse() {
      this.clone.loading = true;
      api
        .post(`/api/v1/courses/${this.$route.params.id}`, {})
        .then((response) => {
          const course = new types.Course(response.data);
          this.$router.push(`/admin/courses/${course.id}`);
        })
        .finally(() => {
          this.clone.loading = false;
        });
    },
    removeCourse() {
      this.remove.loading = true;
      api
        .delete(`/api/v1/courses/${this.$route.params.id}`)
        .then((response) => {
          this.$router.push("/admin/courses");
        })
        .finally(() => {
          this.remove.loading = false;
        });
    },
    watchStatus(status: any) {
      if (status.name == "dirty") {
        this.form.dirty = status.value;
      }
      if (status.name == "invalid") {
        this.form.invalid = status.value;
      }
    },
    optionsDueAt(): any {
      return {
        disabledDate(time: Date) {
          const now = new Date();
          return time < now;
        },
      };
    },
  },
  mounted() {
    this.load();
  },
});
