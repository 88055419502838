
import Vue from "vue";
import LoginApp from "@/components/layouts/LoginApp.vue";
import Dialog from "@/components/layouts/Dialog.vue";
import Codebox from "@/components/Codebox.vue";

import api from "@/api";
import util from "@/util";

export default Vue.extend({
  components: {
    LoginApp,
    Dialog,
    Codebox,
  },
  data: () => ({
    form: {
      error: "",
      rules: {},
    },
    loading: false,
    metadata: null as any, // TODO
    visible: false,
  }),
  props: {
    // flow: String, // TODO
    from: String,
    sessionID: String,
    oneTimeToken: String,
  },
  computed: {},
  methods: {
    verify() {
      this.form.error = "";
      this.loading = true;
      this.visible = false;
      this.metadata = null;
      api
        .post("/api/v1/login/ott/verify", {
          sessionID: this.sessionID,
          oneTimeToken: this.oneTimeToken,
        })
        .then((response) => {
          this.metadata = response.data;
          this.visible = true;
        })
        .catch((error) => {
          this.form.error = util.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    next() {
      // TODO move flow handling from /login/password here?
      // Drop hash when redirecting
    },
    back() {
      if (util.safeURL(this.from)) {
        document.location.assign(this.from);
      }
    },
  },
  mounted() {
    this.verify();
  },
  destroyed() {
    this.visible = false;
  },
});
