
import Vue from "vue";
import Main from "@/components/layouts/Main.vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import CardItem from "@/components/layouts/CardItem.vue";

export default Vue.extend({
  components: {
    Main,
    MultiCard,
    CardItem,
  },
  data: () => ({}),
  computed: {},
  methods: {},
});
