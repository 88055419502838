import { AxiosError, AxiosResponse } from "axios";
import { Notification } from "@acronis/ui-kit";

import base64js from "base64-js";
import dayjs from "dayjs";
import parser from "ua-parser-js";
import relativeTime from "dayjs/plugin/relativeTime";

import * as consts from "@/consts";
import router from "@/router";
import store from "@/store";
import * as types from "@/types";

dayjs.extend(relativeTime);

export default {
  copy(text: string) {
    navigator.clipboard.writeText(text);
    Notification.success({
      title: "Copied to clipboard",
      message: "",
      showClose: false,
    });
  },

  toDate(ds: string): string {
    if (!ds) return "never";
    return dayjs(ds).format("D MMMM YYYY");
  },

  toDatetime(ds: string): string {
    if (!ds) return "never";
    return dayjs(ds).format("D MMMM YYYY HH:mm");
  },

  toRelativeDate(ds: string): string {
    if (!ds) return "never";
    return dayjs(ds).fromNow();
  },

  normalize(s: string): string {
    if (!s) {
      return "";
    }
    return s
      .trim()
      .toLowerCase()
      .replaceAll(/[^a-z0-9- ]+/gi, "")
      .replaceAll(/[- ]+/gi, "-");
  },

  readFile(f: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const r = new FileReader();
      r.onload = (e: ProgressEvent<FileReader>) => {
        resolve(e.target?.result as string);
      };
      r.readAsText(f);
    });
  },

  readData(f: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const r = new FileReader();
      r.onload = (e: ProgressEvent<FileReader>) => {
        resolve(btoa(e.target?.result as string));
      };
      r.readAsBinaryString(f);
    });
  },

  b64d(value: string) {
    return Uint8Array.from(
      atob(value
        .replace(/-/g, "+")
        .replace(/_/g, "/")
      ),
      (c) => c.charCodeAt(0)
    );
  },

  b64(value: Uint8Array) {
    return base64js
      .fromByteArray(new Uint8Array(value))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");
  },

  get redirect(): URL | undefined {
    return this.redirectSSO || this.redirectZTA;
  },

  get redirectZTA(): URL | undefined {
    const redirect = router.currentRoute.query.redirect as string;
    if (redirect) {
      return this.safeURL(redirect as string);
    }
  },

  get redirectSSO(): URL | undefined {
    const from = router.currentRoute.query.from as string | null;
    if (from) {
      let u: URL;
      try {
        u = new URL(from, document.location.origin);
      } catch (error) {
        return;
      }
      const oauth = from.startsWith("/oauth");
      if (oauth) {
        const redirectURI = this.safeURL(u.searchParams.get("redirect_uri") || undefined);
        if (redirectURI) {
          return redirectURI;
        }
      }
      const saml = from.startsWith("/saml");
      if (saml) {
        const relayState = this.safeURL(u.searchParams.get("RelayState") || undefined);
        if (relayState) {
          return relayState;
        }
      }
      if (oauth || saml) {
        return this.safeURL(u.toString());
      }
    }
  },

  safeURL(url?: string): URL | undefined {
    const u = this.url(url);
    if (!u) {
      return;
    }
    if (consts.SAFE_PROTOCOLS.includes(u.protocol)) {
      for (const h of consts.SAFE_HOSTNAMES) {
        if (u.hostname.endsWith(h)) {
          return u;
        }
      }
    }
  },

  shortURL(url: string): string | undefined {
    const u = this.url(url);
    if (!u) {
      return
    }
    return `${u.hostname}${u.pathname}`.replace(/\/+$/, "");
  },

  url(url?: string): URL | undefined {
    if (!url) {
      return;
    }
    let u: URL;
    try {
      u = new URL(url);
    } catch (error) {
      return;
    }
    return u;
  },

  query(opts: any): string {
    const q = new URLSearchParams();
    for (const [key, val] of Object.entries(opts)) {
      if (val) {
        q.set(key, String(val));
      }
    }
    return q.toString();
  },

  error(err: Error): string {
    if (err instanceof DOMException) {
      return err.message.split(".")[0];
    }
    const r = (err as AxiosError).response as AxiosResponse;
    return r ? r.data.message : err.message;
  },

  flag(countryCode: string): string {
    const codePoints = countryCode
      .toUpperCase()
      .split("")
      .map((s: string) => 127397 + s.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
  },

  parseUserAgent(ua: string): parser.IResult {
    return parser(ua);
  },

  userAgentString(userAgent: string) {
    const ua = parser(userAgent);
    return `${ua.browser.name}, ${ua.os.name}`;
  },

  locationString(location: types.Location) {
    if (location.city) {
      return `${location.city}, ${location.country}`;
    } else {
      return location.country;
    }
  },

  get userAgent(): parser.IResult {
    return this.parseUserAgent(window.navigator.userAgent);
  },

  colorFromSeverity(severity: string): string {
    switch (severity.toLowerCase()) {
      case consts.SEVERITY_INFO:
        return "info";
      case consts.SEVERITY_WARNING:
        return "warning";
      case consts.SEVERITY_CRITICAL:
        return "danger";
    }
    return "";
  },

  scroll(hash: string) {
    if (hash) {
      const anchor = document.querySelector(hash);
      if (anchor) {
        window.scrollBy({ top: anchor.getBoundingClientRect().top - 96 });
      }
    }
  },

  cnFromDN(dn: string): string {
    if (!dn) {
      return "-";
    }
    let cn = dn.split(",")[0].split("=")[1];
    if (dn === store.state.account?.attrs.dn) {
      cn += " (You)";
    }
    return cn;
  },

  openTab(link: string) {
    window.open(link, "_blank", "noopener noreferrer");
  },

  browserSupportsWebAuthn(): boolean {
    return (
      window.PublicKeyCredential !== undefined && typeof window.PublicKeyCredential === "function"
    );
  },

  random(lt: number): number {
    return Math.floor(Math.random() * lt);
  },

  titleCase(s?: string): string {
    if (!s) {
      return "";
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
  },

  stringify(value: any): string {
    return JSON.stringify(value, undefined, 4);
  }
};
