
import Vue from "vue";
import AsideMain from "@/components/layouts/AsideMain.vue";
import Outline from "@/components/layouts/Outline.vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import CardItem from "@/components/layouts/CardItem.vue";
import Anchor from "@/components/Anchor.vue";
import AccessRequests from "@/views/admin/accounts/AccessRequests.vue";
import AccessToken from "@/views/admin/accounts/AccessToken.vue";
import Applications from "@/views/admin/accounts/Applications.vue";
import Courses from "@/views/admin/accounts/Courses.vue";
import Devices from "@/views/admin/accounts/Devices.vue";
import Events from "@/views/admin/accounts/Events.vue";
import Groups from "@/views/admin/accounts/Groups.vue";
import Passkeys from "@/views/admin/accounts/Passkeys.vue";
import SSHKey from "@/views/admin/accounts/SSHKey.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    AsideMain,
    Outline,
    MultiCard,
    CardItem,
    Anchor,
    AccessToken,
    AccessRequests,
    Applications,
    Courses,
    Devices,
    Events,
    Groups,
    Passkeys,
    SSHKey,
  },
  data: () => ({
    account: null as types.Account | null,
    loading: true,
    outline: [
      {
        text: "Personal information",
        link: "#personal-information",
      },
      {
        text: "Sign-in and security",
        link: "#sign-in-and-security",
      },
    ],
  }),
  computed: {},
  methods: {
    load() {
      this.loading = true;
      api
        .get(`/api/v1/accounts/${this.$route.params.id}`)
        .then((response) => {
          this.account = new types.Account(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.load();
  },
});
