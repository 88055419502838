import util from "@/util";

const N = util.random(10);

class Base {
  constructor(obj?: any) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

class Model extends Base {
  id!: string;
  createdAt!: string;
  updatedAt?: string;
  deletedAt?: string;
}

export class LoginResponse extends Model {
  hasPasskeys!: boolean;
  isExpired!: boolean;
  isPasswordExpired!: boolean;
  isPasswordChangeRequired!: boolean;
}

export class OneTimeToken extends Base {
  origin!: string;
  sessionID!: string;
  oneTimeToken!: string;
  expiresAt!: string;
}

export class SessionMetadata extends Base {
  ip!: string;
  location!: Location;
  userAgent!: string;
}

export class Session extends Model {
  metadata!: SessionMetadata;
  firstFactor!: string;
  secondFactor!: string;
  isZeroTrust!: boolean;
  passkey!: Passkey;
}

export class Location extends Model {
  countryCode!: string;
  country!: string;
  city!: string;
}

export class Password extends Model {
  password!: string;
}

export class Passkey extends Model {
  displayName!: string;
  publicKeyID!: string;
  createdSessionMetadata!: SessionMetadata;
  lastUsedSessionMetadata?: SessionMetadata;
}

export class AccessToken extends Model {
  expiresAt!: string;
  claims!: Claims;
  token?: string;
}

export class Account extends Model {
  attrs!: AccountAttributes;
  props!: AccountProperties;

  lastAccessToken?: AccessToken;

  get avatar(): string | undefined {
    if (this.attrs.thumbnailPhoto) {
      return `data:image/png;base64,${this.attrs.thumbnailPhoto}`;
    }
    try {
      return `/assets/avatar-${N}.svg`;
    } catch (error) {
      return undefined;
    }
  }

  get manager(): string {
    return util.cnFromDN(this.attrs.manager);
  }
}

export class AccountAttributes extends Base {
  accountExpires?: string;
  co!: string;
  company!: string;
  department!: string;
  displayName!: string;
  dn!: string;
  employeeID?: number;
  givenName!: string;
  lastLogonTimestamp!: string;
  mail!: string;
  manager!: string;
  memberOf!: string[];
  mobile!: string;
  objectGUID!: string;
  primaryGroupID!: number;
  pwdLastSet?: string;
  sAMAccountName!: string;
  sn!: string;
  thumbnailPhoto?: string;
  title!: string;
  userAccountControl?: number;
  userPrincipalName!: string;
  whenChanged!: string;
  whenCreated!: string;
}

export class AccountProperties extends Base {
  isInternalUser!: boolean;
  isExternalUser!: boolean;
  isServiceAccount!: boolean;
  isAdmin!: boolean;
  isApplicationAdmin!: boolean;
  isLicensingAdmin!: boolean;
  isTrainingAdmin!: boolean;
}

export class Course extends Model {
  displayName!: string;
  externalID!: string;
  dueAt!: string;
  includeEveryone!: boolean;
  includeGroups?: string[];
  excludeGroups?: string[];
  excludeUsers?: string[];
  excludeExternalUsers!: boolean;
  enabled!: boolean;
}

export class CourseProgress extends Base {
  course!: Course;
  score!: number;
  progress!: number;
  passed!: boolean;
  finishedAt!: string;
}

export class PagedResponse extends Model {
  limit!: number;
  offset!: number;
  total!: number;
  items!: any[];
}

export class Reference extends Base {
  text!: string;
  link!: string;
}

export class SSHKey extends Model {
  publicKey!: string;
}

// Link only exists because UI Kit requires such structure for search autocomplete
export class Link extends Model {
  value!: string;
  link!: string;
}

export class Claims extends Model {
  jti!: string;
  name!: string;
  email!: string;
  sub!: string;
  iat!: number;
  exp!: number;
  type!: string;
  ip!: string;
}

export class Request extends Model {
  url!: string;
  status!: number;
  message!: string;
}

export class Ticket extends Model { }

export class Group extends Model {
  attrs!: GroupAttributes;
  props!: GroupProperties;
}

export class GroupAttributes extends Base {
  displayName!: string;
  dn!: string;
  mail!: string;
  managedBy!: string;
  member!: string[];
  memberOf!: string[];
  objectGUID!: string;
  sAMAccountName!: string;
}

export class GroupProperties extends Base {
  isDistributionGroup!: boolean;
  isSecurityGroup!: boolean;
}


export class License extends Model {
  name!: string;
  description!: string;
  expiresIn!: number;
  homepage!: string;
  download!: string;
  type!: string;
}

export class LicenseKey extends Model {
  key!: string;
  expiresAt!: string;
}

export class MetricsAccounts extends Base {
  active!: number;
  haveTwoOrMorePasskeys!: number;
  total!: number;
}

export class CourseMetricsTimeSeriesEntry extends Base {
  timestamp!: string;
  passed!: number;
  progress!: number;
}

export class CourseMetrics extends Base {
  passed!: number;
  total!: number;
  progress?: number;
  series?: CourseMetricsTimeSeriesEntry[];
}

export class System extends Model {
  name!: string;
  roles!: Role[];
}

export class Role extends Model {
  name!: string;
}

export class AccessRequest extends Model {
  approved!: boolean;
  expiresAt!: string;
  role!: Role;
  system!: System;
}

export class Counter extends Model {
  total!: number;
}

class CheckupItem extends Model {
  ok!: boolean;
  must!: boolean;
}

export class Expiration extends CheckupItem {
  expiresAt!: string;
  isExpired!: boolean;
  isExpiring!: boolean;
}

export class Count extends CheckupItem {
  total!: number;
}

export class ExternalAccountsCheckupItem extends CheckupItem {
  account!: Account;
  expiration!: Expiration;
}

export class ExternalAccountsCheckup extends CheckupItem {
  items!: ExternalAccountsCheckupItem[];
}

export class ServiceAccountsCheckupItem extends CheckupItem {
  account!: Account;
  password!: Expiration;
  token!: Expiration;
}

export class ServiceAccountsCheckup extends CheckupItem {
  items!: ServiceAccountsCheckupItem[];
}

export class CoursesCheckupItem extends CheckupItem {
  progress!: CourseProgress;
  deadline!: Expiration;
}

export class CoursesCheckup extends CheckupItem {
  items!: CoursesCheckupItem[];
}

export class Checkup extends CheckupItem {
  password!: Expiration;
  passkeys!: Count;
  externalAccounts!: ExternalAccountsCheckup;
  serviceAccounts!: ServiceAccountsCheckup;
  courses!: CoursesCheckup;
}

export class Notification {
  title!: string;
  message?: string;
  type?: string;
  duration?: number;
}

export class OIDCAttributes extends Base {
  subject!: string;
}

export class RelyingParty extends Base {
  clientID!: string;
  clientRedirectURIs!: string[];
  clientAuthMethod!: string;
  attributes!: OIDCAttributes;
}

export class ClientCredentials extends Base {
  clientSecret!: string;
}

export class ApplicationRole extends Base {
  displayName!: string;
  group!: string;
}

export class SAMLAttributes extends Base {
  nameID!: string;
  nameIDFormat!: string;
  idAttributeName!: string;
  usernameAttributeName!: string;
  nameAttributeName!: string;
  firstNameAttributeName!: string;
  lastNameAttributeName!: string;
  emailAttributeName!: string;
  groupsAttributeName!: string;
  rolesAttributeName!: string;
}

export class ServiceProvider extends Base {
  entityID!: string;
  metadata?: string;
  metadataURL?: string;
  attributes!: SAMLAttributes;
  shortcut?: string;
  relayState?: string;
}

export class ZTNAApplication extends Base {
  id!: string;
  address!: string;
  networks!: string[];
}

export class Application extends Model {
  displayName!: string;
  url!: string;
  icon?: string;
  groups!: string[];
  roles!: ApplicationRole[];
  allowEveryone!: boolean;
  allowAlways!: boolean;
  enabled!: boolean;
  oidc?: RelyingParty | null;
  saml?: ServiceProvider | null;
  ztna?: ZTNAApplication | null;
  iamp?: System | null;

  get favicon(): string | undefined {
    return this.icon ? `data:image/png;base64,${this.icon}` : undefined;
  }

  get shortcut(): string | undefined {
    return this.saml?.shortcut ? `${document.location.origin}/saml/v2/sso/${this.saml.shortcut}` : undefined;
  }
}

export class EventMetadataSubject extends Base {
  account?: Account;
  session?: Session;
}

export class EventMetadataObject extends Base {
  account?: Account;
  application?: Application;
  passkey?: Passkey;
  session?: Session;
  accessRequest?: AccessRequest;
  accessToken?: AccessToken;
}

export class EventMetadataChange extends Base {
  type!: string;
  path!: string;
}

export class Event extends Model {
  category!: string;
  severity!: string;
  type!: string;
  subject?: EventMetadataSubject;
  object?: EventMetadataObject;
  reason?: string;
  changelog?: EventMetadataChange[];
}

export class RequestInfo extends Base {
  metadata!: SessionMetadata;
}

export class DebugInfo extends Base {
  session!: Session;
  request!: RequestInfo;
}
