
import Vue from "vue";
import LoginApp from "@/components/layouts/LoginApp.vue";
import Courses from "@/views/checkup/Courses.vue";
import Password from "@/views/checkup/Password.vue";
import Passkeys from "@/views/checkup/Passkeys.vue";
import ExternalAccounts from "@/views/checkup/ExternalAccounts.vue";
import ServiceAccounts from "@/views/checkup/ServiceAccounts.vue";

import api from "@/api";
import * as types from "@/types";
import util from "@/util";

export default Vue.extend({
  components: {
    LoginApp,
    Courses,
    Password,
    Passkeys,
    ExternalAccounts,
    ServiceAccounts,
  },
  data: () => ({
    checkup: new types.Checkup(),
    loading: false,
    random: "",
    error: "",
  }),
  props: {
    flow: String,
    from: String,
  },
  computed: {},
  methods: {
    loginVerify() {
      this.checkup = new types.Checkup();
      this.loading = true;
      this.error = "";
      api
        .post("/api/v1/login/verify", {})
        .then((response) => {
          this.checkup = new types.Checkup(response.data);
          if (this.checkup.ok) {
            this.next();
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          this.error = util.error(error);
          this.loading = false;
        });
    },
    next() {
      if (util.redirectSSO) {
        document.location.assign(this.from);
      } else if (util.redirectZTA) {
        document.location.assign(util.redirectZTA.href);
      } else {
        this.$router.push(this.from || "/");
      }
    },
    back() {
      this.$cookies.remove("email");
      this.$router.push({
        path: "/login",
        query: this.$route.query,
        hash: this.$route.hash,
      });
    },
  },
  mounted() {
    if (this.$store.state.session.isZeroTrust) {
      this.next();
    } else {
      this.loginVerify();
    }
  },
});
