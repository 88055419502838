
import Vue from "vue";
import AsideMain from "@/components/layouts/AsideMain.vue";
import Header from "@/components/layouts/Header.vue";
import Icon from "@/components/Icon.vue";
import Logo from "@/components/Logo.vue";
import LinkCard from "@/components/layouts/LinkCard.vue";
import Outline from "@/components/layouts/Outline.vue";
import ContactHelpDesk from "@/components/ContactHelpDesk.vue";
import FAQ from "@/views/support/FAQ.vue";
import HowToChangePassword from "@/views/support/HowToChangePassword.vue";
import HowToRecoverAccess from "@/views/support/HowToRecoverAccess.vue";
import HowToSignInToPassport from "@/views/support/HowToSignInToPassport.vue";
import HowToWorkWithPasskeys from "@/views/support/HowToWorkWithPasskeys.vue";

import * as types from "@/types";

class Article {
  name!: string;
  slug!: string;
  icon?: string;
  component: any;
}

export default Vue.extend({
  components: {
    AsideMain,
    Header,
    Icon,
    Logo,
    LinkCard,
    Outline,
    ContactHelpDesk,
    FAQ,
    HowToChangePassword,
    HowToRecoverAccess,
    HowToSignInToPassport,
    HowToWorkWithPasskeys,
  },
  data: () => ({
    helpdesk: false,
    selected: null as Article | null,
    contents: [] as Array<types.Reference>,
    articles: [
      {
        name: "Change or reset your password",
        slug: "change-or-reset-your-password",
        icon: "",
        component: HowToChangePassword,
      },
      {
        name: "Sign in to your Passport account",
        slug: "sign-in-to-your-Passport-account",
        icon: "",
        component: HowToSignInToPassport,
      },
      {
        name: "Use a passkey for 2-step verification",
        slug: "use-a-passkey-for-2-step-verification",
        icon: "",
        component: HowToWorkWithPasskeys,
      },
      {
        name: "Lost or stolen device",
        slug: "lost-or-stolen-device",
        icon: "",
        component: HowToRecoverAccess,
      },
      {
        name: "Frequently asked questions",
        slug: "frequently-asked-questions",
        icon: "",
        component: FAQ,
      },
    ] as Array<Article>,
  }),
  computed: {},
  methods: {},
  mounted() {
    if (this.$route.params.id) {
      this.articles.forEach((article) => {
        if (article.slug === this.$route.params.id) {
          this.selected = article;
        }
      });
    }
    if (this.selected) {
      this.$nextTick(() => {
        this.contents = [];
        document.querySelectorAll("h2 .anchor").forEach((anchor) => {
          const title = anchor.textContent!.trim();
          this.contents.push(
            new types.Reference({ text: title, link: `#${anchor.id}` })
          );
        });
      });
    } else {
      this.$router.push("/support");
    }
  },
});
