
import Vue from "vue";
import AsideMain from "@/components/layouts/AsideMain.vue";
import Dialog from "@/components/layouts/Dialog.vue";
import Footer from "@/components/layouts/Footer.vue";
import Outline from "@/components/layouts/Outline.vue";
import Alert from "@/components/Alert.vue";
import Anchor from "@/components/Anchor.vue";
import MultiInput from "@/components/MultiInput.vue";
import Events from "@/views/admin/applications/Events.vue";
import Roles from "@/views/admin/applications/Roles.vue";

import api from "@/api";
import * as types from "@/types";
import store from "@/store";
import util from "@/util";

export default Vue.extend({
  components: {
    AsideMain,
    Dialog,
    Footer,
    Outline,
    Alert,
    Anchor,
    MultiInput,
    Events,
    Roles,
  },
  data() {
    return {
      application: new types.Application(),
      loading: true,
      form: {
        application: new types.Application(),
        sso: "",
        error: "",
        metadataError: "",
        dirty: false,
        invalid: true,
        rules: {
          displayName: [{ required: true }],
          url: [{ required: true }],
        },
      },
      update: {
        loading: false,
        notification: {
          visible: false,
        },
      },
      updateStatus: {
        loading: false,
      },
      updateSecret: {
        credentials: new types.ClientCredentials(),
        loading: false,
        visible: false,
      },
      validateMetadata: {
        loading: false,
      },
      clone: {
        loading: false,
      },
      remove: {
        loading: false,
        visible: false,
        form: {
          name: "",
          error: "",
          rules: {
            name: [{ required: true }],
          },
        },
      },
      iamp: {
        systems: new types.PagedResponse(),
        loading: true,
      },
      outline: [
        {
          text: "General",
          link: "#general",
        },
        {
          text: "Identity and Access Management",
          link: "#identity-and-access-management",
        },
        {
          text: "Zero Trust Access",
          link: "#zero-trust-access",
        },
        {
          text: "Single Sign-On",
          link: "#single-sign-on",
        },
        {
          text: "Recent Security Activity",
          link: "#recent-security-activity",
        },
      ],
    };
  },
  computed: {
    readonly(): boolean {
      return !store.getters.hasApplicationAdminRole();
    },
    origin(): string {
      return document.location.origin;
    },
    discoveryEndpoint(): string {
      return `${this.origin}/.well-known/openid-configuration`;
    },
    authorizeEndpoint(): string {
      return `${this.origin}/oauth/v2/authorize`;
    },
    endSessionEndpoint(): string {
      return `${this.origin}/oauth/v2/end_session`;
    },
    tokenEndpoint(): string {
      return `${this.origin}/oauth/v2/token`;
    },
    userinfoEndpoint(): string {
      return `${this.origin}/oauth/v2/userinfo`;
    },
    metadataEndpoint(): string {
      return `${this.origin}/saml/v2/metadata`;
    },
    loginEndpoint(): string {
      return `${this.origin}/saml/v2/sso`;
    },
    logoutEndpoint(): string {
      return `${this.origin}/saml/v2/slo`;
    },
  },
  methods: {
    load() {
      this.loading = true;
      api
        .get(`/api/v1/applications/${this.$route.params.id}`)
        .then((response) => {
          this.application = new types.Application(response.data);
          if (this.application.oidc) {
            this.form.sso = "oidc";
          }
          if (this.application.saml) {
            this.form.sso = "saml";
          }
          this.form.application = new types.Application(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadSystems() {
      this.iamp.loading = true;
      api
        .get("/api/v1/systems")
        .then((response) => {
          this.iamp.systems = new types.PagedResponse(response.data);
        })
        .finally(() => {
          this.iamp.loading = false;
        });
    },
    updateApplication() {
      this.update.loading = true;
      const app = this.form.application;
      switch (this.form.sso) {
        case "oidc":
          app.saml = null;
          break;
        case "saml":
          app.oidc = null;
          break;
        default:
          app.saml = null;
          app.oidc = null;
      }
      api
        .put(`/api/v1/applications/${this.$route.params.id}`, app)
        .then((response) => {
          this.update.notification.visible = true;
          this.form.dirty = false;
          this.load();
        })
        .finally(() => {
          this.update.loading = false;
        });
    },
    updateApplicationStatus(enabled: boolean) {
      this.updateStatus.loading = true;
      api
        .put(`/api/v1/applications/${this.$route.params.id}/status`, {
          enabled: enabled,
        })
        .then((response) => {
          this.update.notification.visible = true;
          this.application.enabled = enabled;
          this.form.application.enabled = enabled;
        })
        .finally(() => {
          this.updateStatus.loading = false;
        });
    },
    cloneApplication() {
      this.clone.loading = true;
      api
        .post(`/api/v1/applications/${this.$route.params.id}`, {})
        .then((response) => {
          const app = new types.Application(response.data);
          this.$router.push(`/admin/applications/${app.id}`);
        })
        .finally(() => {
          this.clone.loading = false;
        });
    },
    removeApplication() {
      this.remove.loading = true;
      api
        .delete(`/api/v1/applications/${this.$route.params.id}`)
        .then((response) => {
          this.$router.push("/admin/applications");
        })
        .finally(() => {
          this.remove.loading = false;
        });
    },
    updateApplicationSecret() {
      this.updateSecret.loading = true;
      api
        .put(`/api/v1/applications/${this.$route.params.id}/secret`, {})
        .then((response) => {
          this.updateSecret.credentials = new types.ClientCredentials(
            response.data
          );
          this.updateSecret.visible = true;
        })
        .finally(() => {
          this.updateSecret.loading = false;
        });
    },
    validateApplicationMetadata(metadata: string) {
      this.validateMetadata.loading = true;
      this.form.metadataError = "";
      api
        .post(`/api/v1/applications/${this.$route.params.id}/metadata`, {
          metadata: metadata,
        })
        .then((response) => {
          this.form.application.saml = new types.ServiceProvider(response.data);
        })
        .catch((error) => {
          this.form.metadataError = util.error(error);
        })
        .finally(() => {
          this.validateMetadata.loading = false;
        });
    },
    watchIAM(value: boolean) {
      if (value) {
        if (this.application.iamp) {
          this.form.application.iamp = this.application.iamp;
        } else {
          this.form.application.iamp = new types.System();
        }
      } else {
        this.form.application.iamp = null;
      }
    },
    watchIAMSystems(value: boolean) {
      if (value) {
        this.loadSystems();
      }
    },
    watchZTA(value: boolean) {
      if (value) {
        if (this.application.ztna) {
          this.form.application.ztna = this.application.ztna;
        } else {
          this.form.application.ztna = new types.ZTNAApplication({
            address: this.form.application.url.replace("https://", ""),
          });
        }
      } else {
        this.form.application.ztna = null;
      }
    },
    watchSSO(value: boolean) {
      if (value) {
        if (this.application.saml) {
          this.form.sso = "saml";
          this.watchProtocol("saml");
        } else {
          this.form.sso = "oidc";
          this.watchProtocol("oidc");
        }
      } else {
        this.form.sso = "";
        this.form.dirty = true;
      }
    },
    watchProtocol(protocol: string) {
      if (protocol === "oidc") {
        if (this.application.oidc) {
          this.form.application.oidc = this.application.oidc;
        } else {
          this.form.application.oidc = new types.RelyingParty({
            clientID: this.$route.params.id,
            clientRedirectURIs: [],
            clientAuthMethod: "client_secret_basic",
            attributes: new types.OIDCAttributes({
              subject: "objectGUID",
            }),
          });
        }
      }
      if (protocol === "saml") {
        if (this.application.saml) {
          this.form.application.saml = this.application.saml;
        } else {
          this.form.application.saml = new types.ServiceProvider({
            attributes: new types.SAMLAttributes({
              nameID: "objectGUID",
              nameIDFormat:
                "urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified",
            }),
          });
        }
      }
    },
    downloadApplicationMetadata() {
      util.openTab(`/saml/v2/metadata/${this.$route.params.id}`);
    },
    watchStatus(status: any) {
      if (status.name == "dirty") {
        this.form.dirty = status.value;
      }
      if (status.name == "invalid") {
        this.form.invalid = status.value;
      }
    },
    async readIcon(f: FileList) {
      this.form.application.icon = await util.readData(f[0]);
    },
    async readMetadata(f: FileList) {
      this.validateApplicationMetadata(await util.readFile(f[0]));
    },
  },
  mounted() {
    this.load();
  },
});
